<script setup lang="ts"></script>

<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.2 130.2"
  >
    <circle
      class="path animate-[dash_0.9s_ease-in-out] stroke-primary"
      style="stroke-dasharray: 1000; stroke-dashoffset: 0"
      fill="none"
      stroke-width="6"
      stroke-miterlimit="10"
      cx="65.1"
      cy="65.1"
      r="62.1"
    />
    <polyline
      class="path check stroke-primary opacity-0"
      fill="none"
      stroke-width="6"
      stroke-linecap="round"
      stroke-miterlimit="10"
      points="100.2,40.2 51.5,88.8 29.8,67.5 "
      style="stroke-dasharray: 1000; stroke-dashoffset: -99; animation: dash-check 0.9s 0.35s ease-in-out forwards"
    />
  </svg>
</template>

<style></style>
